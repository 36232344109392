<template>
  <page-container title="设备详情" :breadcrumb="breadcrumb">
  <h3 style="text-align:center;font-size: x-large">扫描页面</h3>
  <div style="font-size: 50px; margin-left: 15%">当前MQTT-ID：<input style="height:50px;width:40%;font-size: 50px"  type="text" v-model.trim="clientId" name="mid" readonly="readonly"/>{{this.isonline==1?'在线':'离线'}}</div>
  <div style="font-size: 50px; margin-left: 17.3%">当前SIP-SID：<input style="height:50px;width:41%;font-size: 50px"  type="text" v-model.trim="sipId" name="sid" readonly="readonly"/>{{this.isonline==1?'在线':'离线'}}</div>
  <div style="font-size: 50px; margin-left: 17.2%">当前SIP-RID：<input style="height:50px;width:41%;font-size: 50px"  type="text" v-model.trim="username" name="sid" readonly="readonly"/>{{this.isonline==1?'在线':'离线'}}</div>
  <div style="font-size: 50px; margin-left: 15%">输入MQTT-ID：<a-input style="height:50px;width:40%;font-size: 50px" type="text" v-model.trim="id" name="sid" @keyup.enter="readyset()"/></div>
    <a-button type="primary" @click="getParams" style="margin-left: 40% ; margin-top: 10px;">读取参数</a-button>
    <a-button type="primary" @click="readyset" style="margin-left: 10% ; margin-top: 10px;">写入参数</a-button>
<!--    <a-button type="primary" @click="message" style="margin-left: 780px ; margin-top: 10px;">信息</a-button>-->
    <a-modal v-model="modalVisible" title="提示信息" :width="760" :centered="true">
      <div ref="modalContainer" class="modal-container">
        <div ref="successmess" v-if="successmess">
          <div style="text-align: center">
            <i class="el-icon-circle-check"  style="font-size: 200px"></i>
          </div>
          <div style="font-size: 60px;text-align: center">ID写入成功，请及时更换终端设备</div>
        </div>
        <div ref="defaultmess" v-if="defaultmess">
          <div style="text-align: center">
             <i class="el-icon-circle-close" style="font-size: 200px;"></i>
          </div>
          <div style="font-size: 60px;text-align: center">ID写入失败，请检查未写入部分</div>
        </div>
        <div ref="paramssuccessmess" v-if="paramssuccessmess">
          <div style="text-align: center">
            <i class="el-icon-circle-check"  style="font-size: 200px"></i>
          </div>
          <div style="font-size: 60px;text-align: center">ID读取成功</div>
        </div>
        <div ref="paramsdefaultmess" v-if="paramsdefaultmess">
          <div style="text-align: center">
            <i class="el-icon-circle-close" style="font-size: 200px;"></i>
          </div>
          <div style="font-size: 60px;text-align: center">ID读取失败</div>
        </div>
      </div>
    </a-modal>
  </page-container>
</template>

<script>
import {getDeviceinfoListByCondition} from "A/ai";
import {getDeviceparamValueList, setDeviceparamValueList} from "A/ai";
import callCenter from "C/modals/callCenter";

export default {
  name: "proManager",
  components: {
  },
  data() {
    return {
      breadcrumb: [
        ...(this.$route.params.prePages || []),
        {
          name: '生产管理',
          path: ''
        },
      ],
      messageVisible: false,
      modalVisible:false,
      successmess:'',
      defaultmess:'',
      paramssuccessmess:'',
      paramsdefaultmess:'',
      id: '',
      clientId: '',
      sipId: '',
      username: '',
      flag: '',
      isonline: 0,
      timer: '',
      devnum: '',
    }
  },
  methods: {
    readyset() {
        this.setSIPSConfig();
        this.setSIPRConfig();
        this.setMqttConfig();
        setTimeout(() => {
          this.readyget();
        }, 4000)
    },
    readyget() {
      if (this.setId()) {
        this.getSIPSConfig();
        this.getSIPRConfig();
        this.getMqttConfig();
        this.getIsonline();
        // clearTimeout(this.timer);  //清除延迟执行
        // this.timer = setTimeout(()=>{   //设置延迟执行
        //   this.clear();
        // },8000);
        setTimeout(() => {
            this.clear();
        }, 5000)
      }
    },
    getParams() {
      if (this.setId()) {
        this.getSIPSConfig();
        this.getSIPRConfig();
        this.getMqttConfig();
        this.getIsonline();
        setTimeout(() => {
          this.clearParams();
        }, 4000)
      }
    },

    setMqttConfig() {
      let params = {
        devicenum: "32011500901320000001",
        data:
            [
              {
                modeltype: "1",
                modelnum: "",
                params: {
                  methord: "PUT",
                  url: "/smart/netwok/mqttclient?service=servicemqttclient&action=setconfig",
                  body: {
                    clientId: this.id,
                    host: {
                      ip: "mqtt-mainserver.u-lake.com",
                      password: "public",
                      port: 1886,
                      tls: 0,
                      user: "admin"
                    },
                    key: "88b179bdaa794f0e93736a37dda2cf34",
                    secret: "147a2cc7130f4fd8a81783915d342a27"
                  }
                }
              }
            ]
      };
      setDeviceparamValueList(params).then(res => {

      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
      return true;
    },
    setSIPSConfig() {
      let params = {
        devicenum: "32011500901320000001",
        data:
            [
              {
                modeltype: "1",
                modelnum: "",
                params: {
                  methord: "PUT",
                  url: "/smart/netwok/sipserver?service=servicesipserver&action=setconfig",
                  body: {
                    ip: "192.168.1.150",
                    port: 6060,
                    transMode: 1,
                    timeout: 3,
                    username: "admin",
                    password: "123456",
                    sipId: this.id,
                    realm: "3201150090"
                  }
                }
              }
            ]
      };
      setDeviceparamValueList(params).then(res => {
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
      return true;
    },
    setSIPRConfig() {
      let params = {
        devicenum: "32011500901320000001",
        data:
            [
              {
                modeltype: "1",
                modelnum: "",
                params: {
                  methord: "PUT",
                  url: "/smart/netwok/sipregister?service=servicesipregister&action=setconfig",
                  body: {
                    ip: "video-mainserver.u-lake.com",
                    port: 5060,
                    transMode: 1,
                    timeout: 3,
                    username: this.id,
                    password: "123456",
                    sipId: "32011500902000000001",
                    realm: "3201150090",
                    regInterval: 60,
                    regExpires: 3600,
                    heartInterval: 60,
                    heartTimes: 3
                  }
                }
              }
            ]
      };
      setDeviceparamValueList(params).then(res => {
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
      return true;
    },
    setId() {
      if (this.id == '') {
        this.id = '32011500901320000001'
      }
      return true;
    },
    getClientConfig(){
      let params = {
        devicenum: this.id,
        data:
            [
              {
                modeltype: "1",
                modelnum: "",
                params: {
                  methord: "GET",
                  url: "/smart/netwok/mqttclient?service=servicemqttclient&action=getconfig",
                }
              }
            ]
      };
      // this.showLoading();
      getDeviceparamValueList(params).then(res => {
        // this.hideLoading();
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.clientId = res.item[0].params.body.clientId;
        }
      }).catch(err => {
        // this.hideLoading();
      })
    },
    getMqttConfig() {
      let params = {
        devicenum: this.id,
        data:
            [
              {
                modeltype: "1",
                modelnum: "",
                params: {
                  methord: "GET",
                  url: "/smart/netwok/mqttclient?service=servicemqttclient&action=getconfig",
                }
              }
            ]
      };
      // this.showLoading();
      getDeviceparamValueList(params).then(res => {
        // this.hideLoading();
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.clientId = res.item[0].params.body.clientId;
        }
      }).catch(err => {
        // this.hideLoading();
      })
    },
    getSIPSConfig() {
      let params = {
        devicenum: this.id,
        data:
            [
              {
                modeltype: "1",
                modelnum: "",
                params: {
                  methord: "GET",
                  url: "/smart/netwok/sipserver?service=servicesipserver&action=getconfig",
                }
              }
            ]
      };
      // this.showLoading();
      getDeviceparamValueList(params).then(res => {
        // this.hideLoading();
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.sipId = res.item[0].params.body.sipId;
        }
      }).catch(err => {
        // this.hideLoading();
      })
    },

    getSIPRConfig() {
      let params = {
        devicenum: this.id,
        data:
            [
              {
                modeltype: "1",
                modelnum: "",
                params: {
                  methord: "GET",
                  url: "/smart/netwok/sipregister?service=servicesipregister&action=getconfig",
                }
              }
            ]
      };
      // this.showLoading();
      getDeviceparamValueList(params).then(res => {
        // this.hideLoading();
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.username = res.item[0].params.body.username;
        }
      }).catch(err => {
        // this.hideLoading();
      })
    },
    clear() {
      if ((this.id == this.clientId && this.id == this.sipId && this.id == this.username) &&this.id!='') {
        // this.$message.success('操作成功');
        this.sertSuccess();
        this.id = '';
        this.clientId = '';
        this.sipId = '';
        this.username = '';
        this.isonline = 0;
      } else {
        // this.$message.error('操作失败，请重新扫码');
        this.sertdefault();
        this.id = '';
        this.clientId = '';
        this.sipId = '';
        this.username = '';
        this.isonline = 0;
      }
    },
  clearParams() {
    if ((this.id == this.clientId && this.id == this.sipId && this.id == this.username) &&this.id!='') {
      // this.$message.success('操作成功');
      this.paramsSertSuccess();
      this.id = '';
      this.clientId = '';
      this.sipId = '';
      this.username = '';
      this.isonline = 0;
    } else {
      // this.$message.error('操作失败，请重新扫码');
      this.paramsSertdefault();
      this.id = '';
      this.clientId = '';
      this.sipId = '';
      this.username = '';
      this.isonline = 0;
    }
  },
    getIsonline() {
      let params = {
        devicenum: this.devnum,
      }
      getDeviceinfoListByCondition(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.isonline = res.item[0].isonline;
          console.log(this.id)
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    sertSuccess(){
      this.modalVisible=true;
      this.successmess=true;
      setTimeout(() => {
        this.modalVisible=false;
      }, 2000);
    },
    sertdefault() {
      this.modalVisible=true;
      this.defaultmess=true;
      setTimeout(() => {
        this.modalVisible=false;
      }, 2000);
    },
  paramsSertSuccess(){
    this.modalVisible=true;
    this.paramssuccessmess=true;
    setTimeout(() => {
      this.modalVisible=false;
    }, 2000);
  },
  paramsSertdefault() {
    this.modalVisible=true;
    this.paramsdefaultmess=true;
    setTimeout(() => {
      this.modalVisible=false;
    }, 2000);
  },
},
}
</script>
<style>
.messageBox-prompt-test{
  width: 33%;
}
</style>
